import React, { useContext } from 'react';
import { useInView } from 'react-intersection-observer';
import Card from '../Card';
import Button from '../Button';
import dynamic from 'next/dynamic';
const CardSlider = dynamic(() => import('../CardSlider'));
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './FeaturedArticles.module.scss';
import { TrackingContext } from '../StreamField/StreamField';
import { trackCtaClickStartpage } from '../../utils/datalayer';

const FeaturedArticles = ({
    items,
    title,
    preamble,
    modulePosition,
    buttonText,
    buttonLink,
    modifiers,
}) => {
    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: true,
    });
    const bannerLevel = useContext(TrackingContext);

    const classes = classNames(
        styles['FeaturedArticles'],
        // This is a workaround because lighthouse considers the contrast
        // too weak because we're doing a transition. However the contrast
        // is fine when the transition is over.
        {
            [styles[`FeaturedArticles--OutOfView`]]:
                !inView,
            [styles[`FeaturedArticles--MiddlePosition`]]:
                modulePosition === 'Middle',
            [styles[`FeaturedArticles--TopPosition`]]:
                modulePosition === 'Top',
            [styles[`FeaturedArticles--BottomPosition`]]:
                modulePosition === 'Bottom'
        }
    );

    return (
        <div className={classes} ref={ref}>
            <div
                className={classNames(
                    styles['FeaturedArticles__ContentContainer'],
                    {
                        [styles[
                            'FeaturedArticles__ContentContainer--isVisible'
                        ]]: inView,
                    }
                )}>
                {title && (
                    <div className={styles['FeaturedArticles__TitleContainer']}>
                        <h2 className={styles['FeaturedArticles__Title']}>
                            {title}
                        </h2>
                    </div>
                )}

                {preamble && (
                    <div className={styles['FeaturedArticles__PreambleContainer']}>
                    <p className={styles['FeaturedArticles__Preamble']}>
                        {preamble}
                    </p>
                    </div>
                )}

                {items && (
                    <div
                        className={classNames(
                            styles['FeaturedArticles__FeaturedCardsContainer']
                        )}>
                        <div
                            className={classNames(
                                styles['FeaturedArticles__FeaturedCards']
                            )}>
                            {items.map((item, i) => {
                                return (
                                    <Card
                                        {...item}
                                        size={item.size}
                                        modifiers={[
                                            styles['FeaturedArticles__Card'],
                                            Boolean(i)
                                                ? styles[
                                                      'FeaturedArticles__Card--Quarter'
                                                  ]
                                                : styles[
                                                      'FeaturedArticles__Card--Half'
                                                  ],
                                        ]}
                                        key={i}
                                    />
                                );
                            })}
                        </div>

                        <div className={styles['FeaturedArticles__Slider']}>
                            <CardSlider
                                items={items}
                                className={
                                    styles['FeaturedArticles__SliderComponent']
                                }
                                modifiers={[
                                    styles['FeaturedArticles__SliderComponent'],
                                ]}
                            />
                        </div>
                    </div>
                )}
                {buttonText && buttonLink?.href && (
                    <div className={styles['FeaturedArticles__Button']}>
                        <Button
                            type="secondary"
                            href={buttonLink.href}
                            text={buttonText}
                            onClick={() => {
                                trackCtaClickStartpage(
                                    bannerLevel,
                                    'show more button',
                                    buttonText
                                );
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

FeaturedArticles.propTypes = {
    items: PropTypes.array,
    title: PropTypes.string,
    preamble: PropTypes.string,
    modulePosition: PropTypes.string
};

FeaturedArticles.defaultProps = {
    items: null,
    title: null,
    preamble: null,
    modulePosition: null,
};

export default FeaturedArticles;
